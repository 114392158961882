import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

/* import mobiscroll css */
import './lib/mobiscroll.react.scss'
import * as Discovery from '@soccerwatch/discovery'

Discovery.init(process.env.REACT_APP_Environment || 'dev', true).then(() => {
  process.env.API_ADDITIONAL = Discovery.API_ADDITIONAL
  process.env.API_USER = Discovery.API_USER
  ReactDOM.render(<App />, document.getElementById('root'))
  registerServiceWorker()
}).catch(err => {
  alert(err)
  console.error(err)
})
