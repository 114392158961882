import React, { Component } from 'react'
import md5 from 'md5'
import mobiscroll from '@mobiscroll/react'
import * as Helper from '../globalHelper'
import ImageUpload from './component/ImageUpload'
import * as Discovery from '@soccerwatch/discovery'
import LottiePreview from './component/LottiePreview'
import { Link } from 'react-router-dom'
import format from 'date-format'

// 'https://europe-west1-sw-sc-de-dev.cloudfunctions.net/api-additional/'

function reduceSponsor(sponsor) {
  // console.log(sponsor)
  return {
    RowKey: sponsor.RowKey,
    clubId: sponsor.clubId,
    image: sponsor.image,
    BannerSquere: sponsor.BannerSquere,
    // size: sponsor.size,
    link: sponsor.link,
    active: sponsor.active,
    checked: sponsor.checked,
    saved: true
  }
}

export default class ClubSponsor extends Component {
  constructor(props) {
    super(props)


    this.newId = 5
    this.state = {
      action: 'loading',
      newItem: undefined,
      items: [],
    }

    this.props.StaticSponsors.getFromServerIfNotLoaded()
  }

  componentDidMount() {
    this.props.StaticSponsors.getFromServerIfNotLoaded()
    this.props.AnimatedSponsors.getFromServerIfNotLoaded()
  }

  /*

 ██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ███████╗██████╗
 ██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██╔════╝██╔══██╗
 ███████║███████║██╔██╗ ██║██║  ██║██║     █████╗  ██████╔╝
 ██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██╔══╝  ██╔══██╗
 ██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗███████╗██║  ██║
 ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

*/


  async handleSaveSponsorCatched(item) {
    try {
      await this.props.StaticSponsors.handleSaveSponsor(item)
    } catch (err) {
      mobiscroll.toast({
        message: 'Update nicht möglich: ' + err,
        color: 'warning'
      })
    }
  }

  getActiveCount = () => {
    const animatedActive = this.props.AnimatedSponsors.getActiveSponsorsCount()
    const staticActive = this.props.StaticSponsors.getActiveSponsorsCount()
    return animatedActive + staticActive
  }

  /*
 ██████╗ ███████╗███╗   ██╗██████╗ ███████╗██████╗
 ██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗
 ██████╔╝█████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝
 ██╔══██╗██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
 ██║  ██║███████╗██║ ╚████║██████╔╝███████╗██║  ██║
 ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
*/

  render() {
    const activeCount = this.getActiveCount()
    if (!this.props.StaticSponsors.state.dataLoaded || !this.props.AnimatedSponsors.state.dataLoaded) {
      return (
        <div className='mbsc-grid mbsc-padding'>
          <div className='mbsc-row mbsc-justify-content-center'>
            <div className='mbsc-col-sm-9 mbsc-col-md-8 mbsc-col-xl-6'>
              {/* <mobiscroll.Form> */}
              <div className='app-tab'>
                <div className='app-header'>
                  <h3>Werbepartner</h3>
                </div>
                <div className='mbsc-btn-group'>
                  <div className='mbsc-grid'>
                    <div className='mbsc-row mbsc-justify-content-between'>
                      <mobiscroll.Button outline disabled onClick={this.props.StaticSponsors.handleAddSponsor}>Neuer Sponsor</mobiscroll.Button>
                      <mobiscroll.Button outline onClick={this.props.StaticSponsors.updateFromServer}>Update</mobiscroll.Button>
                    </div>
                  </div>
                </div>

                <h1>Lade Werbepartner..</h1>
              </div>
              {/* </mobiscroll.Form> */}
            </div>
          </div>
        </div>
      )
    }

    const unsavedChanges = this.state.action === 'unsavedChanges'
    return (
      <div className='mbsc-grid mbsc-padding'>
        <div className='mbsc-row mbsc-justify-content-center'>
          <div className='mbsc-col-sm-9 mbsc-col-md-8  mbsc-col-lg-8 mbsc-col-xl-8'>
            <div className='app-tab'>
              <div className='app-header'>
                <h3>Werbepartner</h3>
              </div>
              <div className='mbsc-btn-group'>
                <div className='mbsc-grid'>
                  <div className='mbsc-row mbsc-justify-content-between'>
                    {!unsavedChanges &&
                      <mobiscroll.Button outline onClick={this.props.StaticSponsors.handleAddSponsor}>Neuer Vereinssponsor</mobiscroll.Button>}

                    {unsavedChanges &&
                      <mobiscroll.Button outline disabled onClick={this.props.StaticSponsors.handleAddSponsor}>Neuer Vereinssponsor</mobiscroll.Button>}
                    <mobiscroll.Button outline onClick={this.props.StaticSponsors.updateFromServer}>{unsavedChanges ? 'Alle Verwerfen' : 'Update'}</mobiscroll.Button>
                  </div>
                </div>
              </div>
              <div className='mbsc-grid'>
                <p>Aktive Werbemittel: {activeCount}/6 </p>
              </div>
              <div className='mbsc-grid'>
                <div className='mbsc-row mbsc-justify-content-center mbsc-no-padding'>
                  <div className='mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-12 mbsc-col-xl-12'>
                    <mobiscroll.Input onChange={(e) => { this.props.StaticSponsors.handleSearch(e.target.value) }}>Suche</mobiscroll.Input>
                  </div>
                  {this.props.items.map(value => {
                    let enabledClubs =  undefined
                    if(value.enabledClubs && value.enabledClubs[0]) {
                      enabledClubs = value.enabledClubs[0]
                    }
                    return (
                      <div key={value.RowKey + ':' + enabledClubs} className='mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-12 mbsc-col-xl-12'>
                        <mobiscroll.Card ref={value.RowKey} collapsible open={!value.saved}>
                          <mobiscroll.CardHeader>
                            <mobiscroll.CardTitle>{value.link}</mobiscroll.CardTitle>
                            <mobiscroll.CardSubtitle>{value.active ? 'Aktiv' : 'Inaktiv'}</mobiscroll.CardSubtitle>
                          </mobiscroll.CardHeader>
                          <mobiscroll.CardContent>
                            <mobiscroll.FormGroup>

                              <div className='mbsc-grid'>
                                <div className='mbsc-row'>
                                  <div className='mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-4'>
                                    <mobiscroll.Input onChange={(e) => { this.props.StaticSponsors.handleUpdateSponsor(value.RowKey, 'link', e.target.value) }} value={value.link}>Link</mobiscroll.Input>
                                  </div>
                                  <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                                    <label> Ausspielung für Club
                                      <mobiscroll.Select
                                        disabled={!value.newItem}
                                        value={enabledClubs}
                                        onSet={(e, inst) => {
                                          this.props.StaticSponsors.handleUpdateSponsor(value.RowKey, 'clubId', inst.getVal())
                                        }}
                                      >
                                        {this.props.user.license.map((license) => <option key={license.data.id} value={license.data.id}>{license.clubName}</option>)}
                                      </mobiscroll.Select>
                                    </label>
                                  </div>

                                  {/* <div className='mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-4'>
                                    <mobiscroll.Input onChange={(e) => { this.props.StaticSponsors.handleUpdateSponsor(value.RowKey, 'size', e.target.value) }} value={(String((value.size)) || '').toLowerCase()}>Paket : s,m,l,xl,xxl</mobiscroll.Input>
                                  </div> */}

                                </div>
                              </div>
                              <mobiscroll.FormGroup collapsible open={!value.saved}>

                                <h3>Bilder</h3>
                                <div className='mbsc-grid'>
                                  <div className='mbsc-row'>
                                    <div className='mbsc-col-12 mbsc-col-md-12 mbsc-col-lg-12'>
                                      <h4>Logo</h4>
                                      300px X 170px
                                      <ImageUpload id={value.RowKey} methode='auto' expectedWidth={300} expectedHeight={170} imageSelectCallback={this.props.StaticSponsors.handleImageUploadPending} imagePreviewUrl={value.image} />
                                    </div>
                                  </div>
                                </div>

                              </mobiscroll.FormGroup>

                            </mobiscroll.FormGroup>

                          </mobiscroll.CardContent>
                          <div className='mbsc-btn-group'>
                            <div className='mbsc-grid'>
                              <div className='mbsc-row mbsc-justify-content-between'>

                                {value.saved &&
                                  <mobiscroll.Button
                                    outline
                                    title={!value.active && activeCount >= 6 ? 'Es können nicht mehr als 6 Werbemittel gleichzeitig Aktiviert werden' : ''}
                                    disabled={(!value.active && activeCount >= 6) || this.props.StaticSponsors.state.working}
                                    color={value.active ? 'success' : 'warning'}
                                    onClick={() => { this.props.StaticSponsors.handleSetActiveSponsor(value.RowKey, !value.active) }}>
                                    {value.active ? 'Deaktivieren' : 'Aktivieren'}
                                  </mobiscroll.Button>}
                                {!value.saved &&
                                  <mobiscroll.Button
                                    outline
                                    disabled={this.props.StaticSponsors.state.working}
                                    color='primary'
                                    onClick={() => { this.handleSaveSponsorCatched(value) }}
                                  >
                                    Speichern
                                  </mobiscroll.Button>}

                                {!value.saved &&
                                  <mobiscroll.Button
                                    color='danger'
                                    onClick={() => { this.props.StaticSponsors.resetEntry(value.RowKey) }}
                                    disabled={this.props.StaticSponsors.state.working}
                                  >
                                    Verwerfen
                                  </mobiscroll.Button>}
                                {value.saved &&
                                  <mobiscroll.Button
                                    color='danger'
                                    onClick={() => { this.props.StaticSponsors.handleDeleteSponsor(value) }}
                                    disabled={this.props.StaticSponsors.state.working}
                                  >
                                    Löschen
                                  </mobiscroll.Button>}
                              </div>
                            </div>
                          </div>

                        </mobiscroll.Card>
                      </div>

                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
