import { Container } from 'unstated-typescript'
import * as Helper from '../globalHelper'
import * as Discovery from '@soccerwatch/discovery'
import mobiscroll from '@mobiscroll/react'
// Why do we need this?
function reduceSponsor(_sponsor) {
  const sponsor = JSON.parse(JSON.stringify(_sponsor))
  return {
    RowKey: sponsor.RowKey,
    enabledClubs: sponsor.enabledClubs,
    image: sponsor.image,
    // BannerSquere: sponsor.BannerSquere,
    // size: sponsor.size,
    link: sponsor.link,
    active: sponsor.active,
    checked: sponsor.checked,
    saved: true,
    fromServer: true,
  }
}

export class StaticSponsorContainer extends Container {

  constructor(user) {
    super()
    this.user = user
    this.state = {
      dataLoaded: false,
      working: false,
      sponsors: [],
      serverItems: {},
      items: [],
    }

  }

  getFromServerIfNotLoaded = async () => {
    if (!this.state.dataLoaded && !this.state.working) {
      await this.updateFromServer()
      this.setState({ dataLoaded: true })
    }
  }

  updateFromServer = async () => {
    this.setState({ working: true })
    const result = await Helper.apiList(Discovery.API_ADDITIONAL_NEXT + '/generic/')
    const items = result.filter((ad) => !ad.instreamType).map(reduceSponsor)
    const serverItems = items.reduce((obj, item) => {
      const copy = reduceSponsor(item)
      obj[item.RowKey] = copy
      return obj
    }, {})
    console.log(items, serverItems)
    this.setState({
      working: false,
      serverItems,
      items,
    })
  }

  getActiveSponsorsCount = () => {
    const active = this.state.items.filter((itm) => itm.active)
    return active.length
  }

  handleAddSponsor = () => {
    let items = JSON.parse(JSON.stringify(this.state.items))
    const newItem = {
      RowKey: Helper.generateKey(),
      enabledClubs: this.user.license[0].data.id,
      image: '',
      // BannerSquere: '',
      // size: 'xxl',
      inStream: true, 
      inSummary: true,
      link: '',
      active: false,
      fromServer: false,
      newItem: true
    }
    items.unshift(newItem)
    const serverItems = JSON.parse(JSON.stringify(this.state.serverItems))
    serverItems[newItem.RowKey] = { ...newItem, ...{ text: 'un' } }
    this.setState({
      action: 'unsavedChanges',
      items: items,
      newItem,
      serverItems,
    })
  }

  async handleSetActiveSponsor(id, active) {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.RowKey === id)
    item.active = active
    await this.handleSaveSponsor(item)
    this.setState({
      items: items
    })
  }

  async storeNewImage(id, data, type) {
    const imageData = data.replace('data:image/png;base64,', '')
    const result = await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/upload/' + Helper.generateKey() + '.' + type, { data: imageData })
    const items = this.state.items.map(e => {
      if (e.RowKey === id) {
        delete e.openImageUploadData
        delete e.openImageUploadType
        e.image = result.url
      }
      return e
    })
    this.setState({
      items: items
    })
  }

  async resetEntry(id) {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const itemIndex = items.findIndex((itm) => itm.RowKey === id)
    if (!items[itemIndex].fromServer) { return }
    items[itemIndex] = JSON.parse(JSON.stringify(this.state.serverItems[id]))

    const unsaved = items.find((itm) => !itm.saved)
    const action = unsaved ? 'unsavedChanges' : 'done'

    this.setState({
      items: items,
      action
    })
  }

  // TODO: Handle in ClubSponsor.page
  handleSearch = (search) => {
    this.state.items = this.state.items.sort((a, b) => {
      if (!search) {
        return a.Name > b.Name ? -1 : 1
      }
      if (!b.name || !a.name) return 0
      return (b.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ? 1 : 0) -
        (a.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ? 1 : 0)
    })
    this.setState(this.state)
    return true
  }

  handleUpdateSponsor = (id, field, value) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    let item = items.find((item) => item.RowKey === id)
    if (field === 'clubId') {
      item['enabledClubs'] = [value]
    } else {
      item[field] = value
    }
    // Check if Object Changed and set Saved Property accordingly
    const serverItem = this.state.serverItems[item.RowKey]
    if (serverItem) {
      const saved = Object.keys(item).reduce((saved, key) => {
        if (key === 'saved') { return saved }
        return item[key] === serverItem[key] && saved
      }, true)
      item.saved = saved
    } else {
      item.saved = false
    }
    this.setState({ items })
    return item.saved
  }

  handleImageUploadPending = (id, imageData, type) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.RowKey === id)
    item.openImageUploadData = imageData
    item.openImageUploadType = type
    item.image = imageData
    item.saved = false
    this.setState({ items })
  }
  handleSaveNewElement = async () => {
    // No New Element, this method should not have been called
    if (this.state.newItem === undefined) {
      console.warn('No new Item to store')
      return this.updateFromServer()
    }
    // Try Save new Sponsor
    try {
      await this.handleSaveSponsor(this.state.newItem)
    } catch (err) {
      mobiscroll.toast({
        message: 'Update nicht möglich: ' + err,
        color: 'warning'
      })
      return
    }
    // WHY TF do we, have have two instances of newitem? One is state.newItem and one inside itemList
    // Mark item in ItemList as Saved
    const items = this.state.items.map(e => {
      if (e.RowKey === this.state.newItem.RowKey) {
        e.saved = true
      }
      return e
    })
    this.setState({
      action: 'done',
      items: items,
      newItem: undefined
    })
  }
  handleDeleteSponsor = async (item) => {
    if (!item.saved || !item.RowKey) {
      return mobiscroll.toast({
        message: 'Löschen nicht möglich',
        color: 'warning'
      })
    }

    const answer = await mobiscroll.confirm({
      title: 'Werbepartner löschen?',
      message: 'Der Werbepartner wird dauerhaft gelöscht und kann nicht wiederhergestellt werden!',
      okText: 'Löschen',
      cancelText: 'Abbrechen'
    })

    if (!answer) { return }

    try {
      await Helper.apiDelete(Discovery.API_ADDITIONAL_NEXT + '/genericById/' + item.RowKey)
      await this.updateFromServer()
      return mobiscroll.toast({
        message: 'Sponsor gelöscht',
        color: 'success'
      })
    } catch (err) {
      return mobiscroll.toast({
        message: 'Löschen nicht möglich' + err,
        color: 'warning'
      })
    }
  }

  throwError(err) {
    this.setState({ working: false })
    throw new Error(err)
  }

  handleSaveSponsor = async (item, duplicate = false) => {
    this.setState({ working: true })
    if (item.RowKey === undefined || !item.RowKey.length) this.throwError('Keine Id')
    if (item.enabledClubs === undefined || !item.enabledClubs) this.throwError('Kein Active Status')
    if (item.image === undefined || !item.image.length) this.throwError('Kein Bild')
    if (item.link === undefined || !item.link.length) this.throwError('Kein Link')
    // if (item.size === undefined || !item.size.length) this.throwError('Kein Link')
    // if (['s', 'm', 'l', 'xl', 'xxl'].indexOf(item.size) === -1) this.throwError('Paket muss s m l oder xl sein')

    let imageURL
    if (item.openImageUploadData !== undefined &&
      item.openImageUploadType !== undefined) {
      imageURL = await this.storeNewImage(item.RowKey, item.openImageUploadData, item.openImageUploadType)
    }

    if (!imageURL && !item.image) throw new Error('Fehlendes Banner')

    if(typeof item.enabledClubs === 'string' || item.enabledClubs instanceof String) {
      item.enabledClubs = [item.enabledClubs]
    }

    if(item.RowKey) {
      await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/genericById/' + item.RowKey, {
        RowKey: item.RowKey,
        enabledClubs: item.enabledClubs,
        image: imageURL || item.image,
        mediaInstream: imageURL || item.image,
        instreamType: 'image',
        inStream: true, 
        inSummary: true,
        // size: item.size,
        link: item.link,
        active: duplicate ? false : item.active
      })
    } else {
      await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/generic/', {
        enabledClubs: item.enabledClubs,
        image: imageURL || item.image,
        mediaInstream: imageURL || item.image,
        instreamType: 'image',
        inStream: true, 
        inSummary: true,
        // size: item.size,
        link: item.link,
        active: duplicate ? false : item.active
      })
    }

    // Update from Server will "setState({ working: false })"
    await this.updateFromServer()
  }
}


const staticSponsorContainer = new StaticSponsorContainer()
export default staticSponsorContainer
