import React, { Component } from 'react'
import * as Axios from 'axios'
import Lottie from 'react-lottie'
import * as hexRgb from 'hex-rgb'
import './LottiePreview.css'
import toHex from 'colornames'

// const LottieTemplate = 'https://storage.googleapis.com/sw-sc-de-assets/lotty/test2.json'
const defaultBackground = 'https://storage.googleapis.com/sw-sc-de-assets/lotty/whiteBanner.png'
const LottieTemplate = 'https://storage.googleapis.com/sw-sc-de-assets/lotty/10sec_sutter_ids_placeholder.json'
export default class LottiePreview extends Component {
  constructor(props) {
    super(props)
    this.updateAnimation = this.updateAnimation.bind(this)
    this.updateAnimationRaw = this.updateAnimationRaw.bind(this)

    this.updateAnimationRaw(LottieTemplate)

    this.state = { animationData: null, isStopped: false, isPaused: false }
  }

  updateAnimationRaw(file) {
    Axios.get(LottieTemplate).then(data => {
      let animationData = data.data
      this.setState({ animationDataRaw: animationData })
      this.updateAnimation()
    }).catch(err => {
      window.alert(err)
    })
  }

  tryParseToHexRgb(colorCode) {
    let RGB
    try {
      RGB = hexRgb(colorCode)
    } catch (err) {
      // toHex tries to Parse Css-Colornames to HexCode
      // const hex = toHex(colorCode)
      // if (!hex) {
      // console.error('Error with', colorCode, err)
      return hexRgb('#ff0000')

      // }
      // RGB = hexRgb(hex)
    }
    return RGB
  }

  updateAnimation() {
    if (!this.props.image || !this.state.animationDataRaw) return
    let animationData = JSON.stringify(this.state.animationDataRaw)

    let firstLine = this.props.firstLine || 'Dynamischer Text : Erste Zeile'
    let secondLine = this.props.secondLine || 'Dynamischer Text : Zweite Zeile'
    let buttonText = this.props.buttonText || 'Zur Webseite'

    if (firstLine === '.' || firstLine === '-') {
      firstLine = ''
    }

    if (secondLine === '.' || secondLine === '-') {
      secondLine = ''
    }
    // console.log('Lines', firstLine, secondLine)
    let combinedLine = `${firstLine}\\r${secondLine}`


    const textColor = this.tryParseToHexRgb(this.props.textColor)
    const buttonColor = this.tryParseToHexRgb(this.props.buttonColor)
    const buttonTextColor = this.tryParseToHexRgb(this.props.buttonTextColor)


    const rgbScale = 100.0 / 255.0
    let rgbButtonScale = [buttonColor.red / 255, buttonColor.green / 255, buttonColor.blue / 255]
    rgbButtonScale = `[${rgbButtonScale[0]},${rgbButtonScale[1]},${rgbButtonScale[2]},0]`

    let rgbButtonTextScale = [buttonTextColor.red / 255.0, buttonTextColor.green / 255.0, buttonTextColor.blue / 255.0]
    rgbButtonTextScale = `[${rgbButtonTextScale[0]},${rgbButtonTextScale[1]},${rgbButtonTextScale[2]},0]`

    let rgbTextScale = [textColor.red / 255.0, textColor.green / 255.0, textColor.blue / 255.0]
    rgbTextScale = `[${rgbTextScale[0]},${rgbTextScale[1]},${rgbTextScale[2]},0]`

    animationData = animationData.replace('"BUTTONHOLDERCOLOR"', `${rgbButtonTextScale}`)
    animationData = animationData.replace('"TEXTHOLDERCOLOR"', `${rgbTextScale}`)
    animationData = animationData.replace('"BUTTONBACKGROUNDCOLOR"', `${rgbButtonScale}`)
    animationData = animationData.replace('BACKGROUNDCOLOR', '#ffffff')
    animationData = animationData.replace('TEXTHOLDER', combinedLine)
    animationData = animationData.replace('BUTTONHOLDER', buttonText)
    // animationData = Replaceall('BACKGROUND', '#ffffff', animationData)

    animationData = JSON.parse(animationData)
    // Logo
    animationData.assets[2].u = ''
    animationData.assets[2].p = this.props.image || 'https://imgur.com/0dqRvDZ.jpg'
    animationData.assets[1].u = ''
    animationData.assets[1].p = this.props.image || 'https://imgur.com/0dqRvDZ.jpg'

    // Background Image
    animationData.assets[0].u = ''
    animationData.assets[0].p = this.props.inStreamBackground || defaultBackground

    this.setState({ animationData: animationData })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.updateAnimation()
    }
  }

  render() {
    if (!this.state.animationData) { return null }
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: this.state.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return <div style={this.props.style}>
      <Lottie options={defaultOptions}
        // height={400}
        // width={400}
        isStopped={this.state.isStopped}
        isPaused={this.props.isPaused} />

    </div>
  }
}
