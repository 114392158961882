import { Container } from 'unstated-typescript'
import * as Helper from '../globalHelper'
import * as Discovery from '@soccerwatch/discovery'
import mobiscroll from '@mobiscroll/react'

function reduceSponsor(_sponsor) {
  const sponsor = JSON.parse(JSON.stringify(_sponsor))
  return {
    id: sponsor.AdId || sponsor.id,
    activeLotty: sponsor.activeLotty !== undefined ? sponsor.activeLotty : false,
    saved: sponsor.saved !== undefined ? sponsor.saved : true,
    name: sponsor.name,
    backgroundLottyColor: sponsor.backgroundLottyColor,
    link: sponsor.link,
    enabledCitys: sponsor.enabledCitys,
    enabledClubs: sponsor.enabledClubs,
    comment: sponsor.comment,
    validFrom: sponsor.validFrom,
    validTill: sponsor.validTill,
    instreamTextA: sponsor.instreamTextA,
    instreamTextB: sponsor.instreamTextB,
    mediaLottyInstream: sponsor.mediaLottyInstream,
    mediaLottyInstreamSide: sponsor.mediaLottyInstreamSide,
    instreamButtonText: sponsor.instreamButtonText,
    instreamButtonColor: sponsor.instreamButtonColor,
    instreamButtonTextColor: sponsor.instreamButtonTextColor,
    instreamTextColor: sponsor.instreamTextColor,
    inSummary: sponsor.inSummary,
    // premiumSponsor: sponsor.size === 'xl',
    accessKey: sponsor.accessKey,
    fromServer: true
  }
}


export class AnimatedSponsorContainer extends Container {

  constructor(user) {
    super()
    this.user = user
    this.state = {
      dataLoaded: false,
      items: [],
      serverItems: {},
      newItem: null
    }

  }

  getFromServerIfNotLoaded = async () => {
    if (!this.state.dataLoaded && !this.state.working) {
      await this.updateFromServer()
      this.setState({ dataLoaded: true })
    }
  }

  updateFromServer = async () => {
    this.setState({ working: true })
    const result = await Helper.apiList(Discovery.API_ADDITIONAL_NEXT + '/generic/')
    const items = result.filter((ad) => ad.instreamType === 'lotty').map(reduceSponsor)
    const serverItems = result.reduce((obj, item) => {
      const parsedItem = reduceSponsor(item)
      obj[parsedItem.id] = parsedItem
      return obj
    }, {})
    this.setState({
      working: false,
      items,
      serverItems
    })
  }

  getActiveSponsorsCount = () => {
    const active = this.state.items.filter((itm) => itm.activeLotty)
    return active.length
  }

  getNewItem = () => {
    return {
      id: Helper.generateKey(),
      name: '',
      link: '',
      comment: '',
      mediaLottyInstream: '',
      // Set enabledCity to first entry of userLicences as default
      enabledCitys: [],
      enabledClubs: [this.user.license[0].data.swcsId],
      saved: false,
      validFrom: new Date(),
      instreamTextA: '',
      instreamTextB: '',
      instreamButtonText: '',
      instreamButtonTextColor: '#ffffff',
      mediaLottyInstreamSide: '',
      instreamButtonColor: '#111111',
      instreamTextColor: '#ffffff',
      // premiumSponsor: false,
      activeLotty: false,
      fromServer: false
    }
  }

  handleAddSponsor = () => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const serverItems = JSON.parse(JSON.stringify(this.state.serverItems))
    const newItem = this.getNewItem()
    items.unshift(newItem)
    serverItems[newItem.id] = { ...newItem, ...{ text: 'un' } }
    this.setState({
      items,
      serverItems,
      newItem
    })
  }

  
  handleSearch = (search) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    items = items.sort((a, b) => {
      if (!search) {
        return a.Name > b.Name ? -1 : 1
      }
      if (!b.name || !a.name) return 0
      return (b.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ? 1 : 0) -
       (a.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ? 1 : 0)
    })
    this.setState({ items })
    return true
  }

  handleUpdateSponsor = (id, field, value, reset) => {
    // let saved = true
    console.log(id, field, value, reset)
    const items = JSON.parse(JSON.stringify(this.state.items))
    const serverItems = JSON.parse(JSON.stringify(this.state.serverItems))
    if (!id && reset) {
      const index = items.findIndex(e => e.id === reset.id)
      if(reset.id === this.state.newItem.id) {
        items.splice(index, 1)
        delete serverItems[reset.id]
        this.setState({ items, serverItems, newItem: undefined })
      } else {
        items[index] = serverItems[reset.id]
        this.setState({ items, serverItems })
      }
      return true
    }
    const index = items.findIndex(e => e.id === id)
    const item = items[index]
    item[field] = value

    const serverItem = serverItems[id]
    if (serverItem) {
      const saved = Object.keys(item).reduce((saved, key) => {
        if (key === 'saved') { return saved }
        return item[key] === serverItem[key] && saved
      }, true)
      item.saved = saved
    } else {
      item.saved = false
    }
    const action = item.saved ? 'done' : 'unsavedChanges'
    console.log(items, serverItem, action)
    this.setState({ items, serverItems, action })

    return true
  }

  handleImageUploadPending = (id, imageData, type) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === id)
    item.openImageUploadData = imageData
    item.openImageUploadType = type
    item.mediaLottyInstream = imageData
    item.saved = false

    this.setState({ items, action: 'unsavedChanges' })
  }

  handleImageBackgroundUploadPending = (id, imageData, type) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === id)
    item.openBackgroundImageData = imageData
    item.openBackgroundImageUploadType = type
    item.mediaLottyInstreamSide = imageData
    item.saved = false

    this.setState({ items, action: 'unsavedChanges' })
  }

   resetEntry = async (id) => {
    const items = JSON.parse(JSON.stringify(this.state.items))
    const itemIndex = items.findIndex((itm) => itm.id === id)
    items[itemIndex] = JSON.parse(JSON.stringify(this.state.serverItems[id]))
    this.handleUpdateSponsor(null, null, null, items[itemIndex])

  }

   storeNewImage = async (id, data, type) => {
    const imageData = data.replace('data:image/png;base64,', '')
    const result = await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/upload/' + Helper.generateKey() + '.' + type, { data: imageData })
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === id)
    delete item.openImageUploadData
    delete item.openImageUploadType
    item.mediaLottyInstream = result.url

    this.setState({
      items: items
    })
    return result.url
  }

   storeNewBackgroundImage = async (id, data, type) => {
    let imageData = data.replace('data:image/png;base64,', '')
    let result = await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/upload/' + Helper.generateKey() + '.' + type, { data: imageData })
    
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === id)
    delete item.openBackgroundImageData
    delete item.openBackgroundImageType
    item.mediaLottyInstreamSide = result.url

    this.setState({
      items: items
    })
    return result.url
  }

 handleSetActiveSponsor = async (id, active) => {
    let updateItem
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === id)
    item.activeLotty = active
    await this.handleSaveSponsor(item)

    this.setState({
      items: items
    })
  }

  handleSaveNewElement = async() => {
    if (this.state.newItem === undefined) {
      console.warn('No new Item to store')
      return this.updateFromServer()
    }


    try {
      await this.handleSaveSponsor(this.state.newItem)
    } catch (err) {
      mobiscroll.toast({
        message: 'Update nicht möglich: ' + err,
        color: 'warning'
      })
      return
    }
    const items = JSON.parse(JSON.stringify(this.state.items))
    const item = items.find((itm) => itm.id === this.state.newItem.id)
    item.saved = true

    this.setState({
      action: 'done',
      items: items,
      newItem: undefined
    })
  }

  handleDeleteSponsor = async (item) => {
    if (!item.saved || !item.id) {
      return mobiscroll.toast({
        message: 'Löschen nicht möglich',
        color: 'warning'
      })
    }

    let answer = await mobiscroll.confirm({
      title: 'Werbepartner löschen?',
      message: 'Der Werbepartner wird dauerhaft gelöscht und kann nicht wiederhergestellt werden!',
      okText: 'Löschen',
      cancelText: 'Abbrechen'
    })

    if (!answer) { return }

    try {
      await Helper.apiDelete(Discovery.API_ADDITIONAL_NEXT + '/genericById/' + item.id)
      await this.updateFromServer()
      return mobiscroll.toast({
        message: 'Sponsor gelöscht',
        color: 'success'
      })
    } catch (err) {
      return mobiscroll.toast({
        message: 'Löschen nicht möglich' + err,
        color: 'warning'
      })
    }
  }

  throwError(err) {
    this.setState({ working: false })
    throw new Error(err)
  }

  handleSaveSponsor = async (item) => {
    this.setState({ working: true })
    if (item.id === undefined || !item.id.length) this.throwError('Keine ID')
    if (item.activeLotty === undefined) this.throwError('Kein Active Status')
    if (item.name === undefined || !item.name.length) this.throwError('Kein Name')
    if (item.link === undefined || !item.link.length) this.throwError('Kein Link')

    let imageURL
    if (item.openImageUploadData !== undefined &&
      item.openImageUploadType !== undefined) {
      imageURL = await this.storeNewImage(item.id, item.openImageUploadData, item.openImageUploadType)
    }

    let imageURL2
    if (item.openBackgroundImageData !== undefined &&
      item.openBackgroundImageUploadType !== undefined) {
      imageURL2 = await this.storeNewBackgroundImage(item.id, item.openBackgroundImageData, item.openBackgroundImageUploadType)
    }

    if (!imageURL && !item.mediaLottyInstream) throw new Error('Fehlendes Banner')
    let instreamTextA = item.instreamTextA || ''
    if (instreamTextA.trim() === '.' || instreamTextA.trim() === '-') {
      instreamTextA = ''
    }
    let instreamTextB = item.instreamTextB || ''
    if (instreamTextB.trim() === '.' || instreamTextB.trim() === '-') {
      instreamTextB = ''
    }
    await Helper.apiPost(Discovery.API_ADDITIONAL_NEXT + '/genericById/' + item.id, {
      backgroundLottyColor: item.backgroundLottyColor || '#FFFFFF',
      link: item.link,
      comment: item.comment,
      activeLotty: item.activeLotty,
      instreamType: 'lotty',
      name: item.name,
      enabledCitys: (String(item.enabledCitys) || '').split(','),
      enabledClubs: (String(item.enabledClubs) || '').split(','),
      validFrom: item.validFrom,
      validTill: item.validTill,
      instreamTextA: instreamTextA || '',
      instreamTextB: instreamTextB || '',
      instreamButtonText: item.instreamButtonText,
      mediaLottyInstream: imageURL || item.mediaLottyInstream,
      mediaLottyInstreamSide: imageURL2 || item.mediaLottyInstreamSide,
      // size: item.premiumSponsor ? 'xl' : 'l',
      inStream: true,
      inSummary: item.inSummary,
      instreamButtonColor: item.instreamButtonColor,
      instreamButtonTextColor: item.instreamButtonTextColor,
      instreamTextColor: item.instreamTextColor
    })
    // updateFromServer will call setState({ working: false }) for us
    await this.updateFromServer()
  }

}

const animatedSponsorContainer = new AnimatedSponsorContainer()
export default animatedSponsorContainer
