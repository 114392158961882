import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import LottiePreview from './component/LottiePreview'
import * as Helper from '../globalHelper'
import * as Discovery from '@soccerwatch/discovery'
import format from 'date-format'

export default class PreviewPage extends Component {
  constructor (props) {
    super(props)
    this.updateFromServer = this.updateFromServer.bind(this)
    this.enable = this.enable.bind(this)
    this.state = {}

    this.updateFromServer()
    setInterval(this.updateFromServer, 20000)
  }

  updateFromServer () {
    const accessKey = this.props.accessKey || this.props.match.params.accessKey
    Helper.apiGet(Discovery.API_ADDITIONAL + '/genericByKey/' + accessKey).then((result) => {
      if (!result.data[0]) { return }
      this.setState({
        previewItem:
        { 
          ...result.data[0],
          ...{
            previewBackground: 'https://storage.googleapis.com/sw-sc-de-shared/25769/thumbnail-4x.jpeg'
          }
        }
      })
    })
  }

  enable (event) {
    this.setState({
      enabled: event.target.checked
    })
  }

  render () {
    const previewItem = this.state.previewItem
    if (!previewItem) {
      return null
    }
    let inActiveTimerange = true
    if (previewItem.validFrom && previewItem.validTill) {
      inActiveTimerange = new Date(previewItem.validFrom).getTime() < new Date().getTime() &&
      new Date(previewItem.validTill).getTime() > new Date().getTime()
    }
    return (
      <mobiscroll.Form>
        <div className='mbsc-grid mbsc-padding'>
          <div className='mbsc-row mbsc-justify-content-center'>
            <div className='mbsc-col-sm-12 mbsc-col-md-9 mbsc-col-xl-7'>
              <h3>Vorschau {previewItem.name}</h3>
              <mobiscroll.Note color={previewItem.activeLotty ? (inActiveTimerange ? 'success' : 'primary') : 'secondary'}>{previewItem.activeLotty ? inActiveTimerange ? 'Aktiv' : 'Freigegeben' : 'Nicht aktiv'}</mobiscroll.Note>
              <h5>Verweist auf <a href={previewItem.link}>{previewItem.link}</a></h5>
              {previewItem.enabledCitys &&
                <h5>Anzeigegebiet : {previewItem.enabledCitys}</h5>
              }
              <h5>Laufzeit {format.asString('dd.MM.yyyy', new Date(previewItem.validFrom))} bis {format.asString('dd.MM.yyyy', new Date(previewItem.validTill))}</h5>

              <img src={previewItem.previewBackground} style={{ width: '100%' }} />
              <a href={previewItem.link}>
                 <LottiePreview style={{ width: '60%', marginLeft: '20%', marginTop: '-9%' }} image={previewItem.mediaLottyInstream} buttonTextColor={previewItem.instreamButtonTextColor} textColor={previewItem.instreamTextColor} inStreamBackground={previewItem.mediaLottyInstreamSide} buttonColor={previewItem.instreamButtonColor} firstLine={previewItem.instreamTextA} secondLine={previewItem.instreamTextB} buttonText={previewItem.instreamButtonText} /> <LottiePreview style={{ width: '60%', marginLeft: '20%', marginTop: '-9%' }} image={previewItem.mediaLottyInstream} buttonTextColor={previewItem.instreamButtonTextColor} textColor={previewItem.instreamTextColor} inStreamBackground={previewItem.mediaLottyInstreamSide} buttonColor={previewItem.instreamButtonColor} firstLine={previewItem.instreamTextA} secondLine={previewItem.instreamTextB} buttonText={previewItem.instreamButtonText} />
              </a>
            </div>
          </div>
        </div>
      </mobiscroll.Form>
    )
  }
}
