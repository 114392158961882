import React, { Component } from 'react'

import mobiscroll from '@mobiscroll/react'
import AdItem from './AdItem'

export default class HomePage extends Component {
  constructor(props) {
    super(props)

    this.newId = 5
    this.state = {
      action: 'loading',
      newItem: undefined,
      items: []
    }

  }
  componentDidMount() {
    this.props.StaticSponsors.getFromServerIfNotLoaded()
    this.props.AnimatedSponsors.getFromServerIfNotLoaded()
  }
  /*

 ██╗  ██╗ █████╗ ███╗   ██╗██████╗ ██╗     ███████╗██████╗
 ██║  ██║██╔══██╗████╗  ██║██╔══██╗██║     ██╔════╝██╔══██╗
 ███████║███████║██╔██╗ ██║██║  ██║██║     █████╗  ██████╔╝
 ██╔══██║██╔══██║██║╚██╗██║██║  ██║██║     ██╔══╝  ██╔══██╗
 ██║  ██║██║  ██║██║ ╚████║██████╔╝███████╗███████╗██║  ██║
 ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

*/

  handleSaveSponsorCatched = async (item) => {
    try {
      await this.props.AnimatedSponsors.handleSaveSponsor(item)
    } catch (err) {
      mobiscroll.toast({
        message: 'Update nicht möglich: ' + err,
        color: 'warning'
      })
    }
  }

  getActiveCount = () => {
    const animatedActive = this.props.AnimatedSponsors.getActiveSponsorsCount()
    const staticActive = this.props.StaticSponsors.getActiveSponsorsCount()
    return animatedActive + staticActive
  }

  /*
 ██████╗ ███████╗███╗   ██╗██████╗ ███████╗██████╗
 ██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗
 ██████╔╝█████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝
 ██╔══██╗██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
 ██║  ██║███████╗██║ ╚████║██████╔╝███████╗██║  ██║
 ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
*/

  render() {
    const activeCount = this.getActiveCount()
    // if (this.state.action === 'loading') {
    if (!this.props.AnimatedSponsors.state.dataLoaded || !this.props.StaticSponsors.state.dataLoaded) {
      return (
        <div className='mbsc-grid mbsc-padding'>
          <div className='mbsc-row mbsc-justify-content-center'>
            <div className='mbsc-col-sm-9 mbsc-col-md-8 mbsc-col-xl-6'>
              <div className='app-tab'>
                <div className='app-header'>
                  <h3>Werbepartner</h3>
                </div>
                <div className='mbsc-btn-group'>
                  <div className='mbsc-grid'>
                    <div className='mbsc-row mbsc-justify-content-between'>
                      <mobiscroll.Button outline disabled onClick={this.handleAddSponsor}>{'Neuer Sponsor'}</mobiscroll.Button>
                      <mobiscroll.Button outline onClick={this.updateFromServer}>Update</mobiscroll.Button>
                    </div>
                  </div>
                </div>
                <h1>Lade Werbepartner..</h1>
              </div>
            </div>
          </div>
        </div>
      )
    }

    let unsavedChanges = this.state.action === 'unsavedChanges'
    return (
      <div className='mbsc-grid mbsc-padding'>
        <div className='mbsc-row mbsc-justify-content-center'>
          <div className='mbsc-col-sm-9 mbsc-col-md-8  mbsc-col-lg-8 mbsc-col-xl-8'>

            <div className='app-tab'>
              <div className='app-header'>
                <h3>Werbepartner</h3>
              </div>
              <div className='mbsc-btn-group'>
                <div className='mbsc-grid'>
                  <div className='mbsc-row mbsc-justify-content-between'>
                    {!unsavedChanges &&
                      <mobiscroll.Button outline onClick={this.props.AnimatedSponsors.handleAddSponsor}>{'Neuer Werbepartner'}</mobiscroll.Button>
                    }

                    {unsavedChanges &&
                      <mobiscroll.Button outline disabled onClick={this.props.AnimatedSponsors.handleAddSponsor}>{'Neuer Werbepartner'}</mobiscroll.Button>
                    }
                    <mobiscroll.Button outline onClick={this.props.AnimatedSponsors.updateFromServer}>{unsavedChanges ? 'Alle Verwerfen' : 'Update'}</mobiscroll.Button>
                  </div>
                </div>
              </div>
              <div className='mbsc-grid'>
                <p>Aktive Werbemittel: {activeCount}/6 </p>
              </div>
              <div className='mbsc-grid'>
                <div className='mbsc-row mbsc-justify-content-center mbsc-no-padding' >
                  <div className='mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-12 mbsc-col-xl-12'>
                    <mobiscroll.Input onChange={(e) => { this.props.AnimatedSponsors.handleSearch(e.target.value) }}>Suche</mobiscroll.Input>
                  </div>
                  {this.props.items.map((value, index) => {
                    return (<AdItem
                      key={value.id}
                      {...value}
                      index={index}
                      user={this.props.user}
                      handleSetActiveSponsor={this.props.AnimatedSponsors.handleSetActiveSponsor}
                      handleSaveSponsorCatched={this.handleSaveSponsorCatched}
                      handleDeleteSponsor={this.props.AnimatedSponsors.handleDeleteSponsor}
                      resetEntry={this.props.AnimatedSponsors.resetEntry}
                      handleImageUploadPending={this.props.AnimatedSponsors.handleImageUploadPending}
                      handleImageBackgroundUploadPending={this.props.AnimatedSponsors.handleImageBackgroundUploadPending}
                      handleUpdateSponsor={this.props.AnimatedSponsors.handleUpdateSponsor}
                      activeCount={activeCount}
                      AnimatedSponsors={this.props.AnimatedSponsors}
                    />
                    )
                  })
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
