import React, { Component } from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import './App.css'
import mobiscroll from '@mobiscroll/react'
import HomePage from './pages/Home.page'
import PreviewPage from './pages/Preview.page'
import LoginPage from './pages/Login.page'
import Cookies from 'universal-cookie'
import ClubSponsor from './pages/ClubSponsor.page'
import * as Helper from './globalHelper'
import * as Discovery from '@soccerwatch/discovery'
import firebase from './firebase'
import jwtDecode from 'jwt-decode'
import { Provider, Subscribe } from 'unstated-typescript'
import { StaticSponsorContainer } from './services/staticSponsorService'
import { AnimatedSponsorContainer } from './services/animatedSponsorService'

// const cookies = new Cookies()

mobiscroll.settings = {
  theme: 'mobiscroll-dark', /* set global theme */
  lang: 'de'
}
// config to use react router
mobiscroll.Route = Route

class App extends Component {
  staticSponsorService
  animatedSponsorService
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      loggedIn: false
    }
    // this.checkLogin(cookies.get('email'), cookies.get('password')).then().catch(err => { alert(err) })
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.handleLogin(user)
      }
    })
  }

  checkLogin = async (email, pw, retry = false) => {
    try {
      const login = await firebase.auth().signInWithEmailAndPassword(email, pw)
      this.handleLogin(login.user)
      return true
    } catch (err) {
      console.error(err)
      if (!retry) {
        const migrated = await Helper.apiPost(`${Discovery.API_USER}/login`, { user: email, pw }, { 'Content-Type': 'application/json' })
        if (migrated.newMigrated) {
          return await this.checkLogin(email, pw, true)
        }
      }
      console.error('Login Failed')
      return false
    }
  }

  getUserLicences = async () => {
    const url = `${Discovery.API_USER}/licensesBySubType/AdManagerAccess/Club`
    const res = await Helper.apiGet(url)
    return res.data
  }

  appendClubNames = async () => {
    const user = JSON.parse(JSON.stringify(this.state.user))
    const userLicenses = user.license
    for (let i in userLicenses) {
      const license = userLicenses[i]
      const url = `${Discovery.API_CLUB}/info/${license.data.id}?view=name`
      try {
        const res = await Helper.apiGet(url)
        license.clubName = res.name
      } catch (err) {
        console.error('Could not get ClubName:',err)
        license.clubName = 'ID: ${license.data.id}'
      }
    }
    this.setState({ user })
  }

  handleLogin = async (gUser) => {
    const token = await gUser.getIdToken()
    const user = jwtDecode(token)
    user.license = await this.getUserLicences()
    
    this.staticSponsorService = new StaticSponsorContainer(user)
    this.animatedSponsorService = new AnimatedSponsorContainer(user)
    this.setState({ loggedIn: true, user }, () => {
      this.appendClubNames(this.state.user)
    })
  }


  logout = () => {
    firebase.auth().signOut()
    this.setState({ loggedIn: false, user: null })
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <Provider inject={[this.staticSponsorService, this.animatedSponsorService]}>
          <BrowserRouter>
            <mobiscroll.Page className='app-page'>
              {/* <Switch> */}
              <Route
                path='/' exact component={() => {
                  return (
                    <Subscribe to={[StaticSponsorContainer, AnimatedSponsorContainer]}>
                      {(StaticSponsors, AnimatedSponsors) =>
                        <HomePage user={this.state.user} StaticSponsors={StaticSponsors} AnimatedSponsors={AnimatedSponsors} items={AnimatedSponsors.state.items} />
                      }</Subscribe>)
                }}
              />
              <Route
                path='/ClubSponsor' exact component={() => {
                  return (<Subscribe to={[StaticSponsorContainer, AnimatedSponsorContainer]}>
                    {(StaticSponsors, AnimatedSponsors) =>
                      <ClubSponsor user={this.state.user} StaticSponsors={StaticSponsors} AnimatedSponsors={AnimatedSponsors} items={StaticSponsors.state.items} />
                    }</Subscribe>)
                }}
              />
              <Route path='/login' component={() => {
                return (
                  <LoginPage loggedIn={this.state.loggedIn} user={this.state.user} logout={this.logout} login={this.checkLogin} />
                )
              }} />
              <Route path='/preview/:accessKey' component={PreviewPage} />
              {/* </Switch> */}
              <mobiscroll.BottomNav>
                <mobiscroll.NavItem to='/' exact icon='home'>Animiert Sponsor</mobiscroll.NavItem>
                <mobiscroll.NavItem to='/ClubSponsor' exact icon='home'>Statisch Sponsor</mobiscroll.NavItem>
                <mobiscroll.NavItem to='/login' icon='user4'>Account</mobiscroll.NavItem>
              </mobiscroll.BottomNav>
            </mobiscroll.Page>
          </BrowserRouter>
        </Provider>
      )
    } else {
      return (
        <BrowserRouter>
          <mobiscroll.Page className='app-page'>
            <Switch>
              <Route path='/preview/:accessKey' component={PreviewPage} />
              <Route path='/' component={() => {
                return (
                  <div>
                    <LoginPage loggedIn={this.state.loggedIn} user={this.state.user} logout={this.logout} login={this.checkLogin} />

                    <mobiscroll.BottomNav>
                      <mobiscroll.NavItem to='/' icon='user4'>Account</mobiscroll.NavItem>
                    </mobiscroll.BottomNav>
                  </div>
                )
              }} />
            </Switch>
          </mobiscroll.Page>
        </BrowserRouter>

      )
    }
  }
}

export default App
