import React from 'react'

import './Login.page.css'
export default class LoginPage extends React.Component {
  render () {
    return (
      <div className='loginContainer'>   
      <aisw-user language="de"></aisw-user>
      </div>
    )
  }
}
