import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import LottiePreview from './component/LottiePreview'
import * as Helper from '../globalHelper'
import * as Discovery from '@soccerwatch/discovery'
import ImageUpload from './component/ImageUpload'
import { Link } from 'react-router-dom'
import format from 'date-format'

export default class AdItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.card = React.createRef()
  }

  componentDidMount() {
    let open = false
    if (this.props.name === '' && !this.props.saved) {
      open = true
    }
    // console.log(this.props.id, 'Mounted', open)
    this.setState({ open })
  }

  toggle = () => {
    const div = document.getElementById(this.props.id)
    const head = div.querySelector('.mbsc-card-header')
    const openAsString = head.getAttribute('aria-expanded')
    const open = openAsString === "false" ? false : true
    this.setState({ open })
  }

  handleDatetime = (e) => {
    let validFrom = format.parse('dd.MM.yyyy', e.valueText)
    this.props.handleUpdateSponsor(this.props.id, 'validFrom', validFrom)
    let validTill = new Date(validFrom)
    validTill.setFullYear(validFrom.getFullYear() + 1)
    this.props.handleUpdateSponsor(this.props.id, 'validTill', validTill)
  }


  render() {
    return (
      <div key={this.props.id} className='mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-12 mbsc-col-xl-12'>
        <div id={this.props.id}>
          <mobiscroll.Card ref={this.card} collapsible open={!this.props.saved} onClick={this.toggle}>
            <mobiscroll.CardHeader>
              <mobiscroll.CardTitle>{this.props.name}</mobiscroll.CardTitle>
              <mobiscroll.CardSubtitle>{this.props.activeLotty ? 'Aktiv' : 'Inaktiv'}</mobiscroll.CardSubtitle>
            </mobiscroll.CardHeader>
            <mobiscroll.CardContent>
              <div style={{ minHeight: '400px' }}>

                <mobiscroll.FormGroup>
                  <mobiscroll.FormGroup collapsible open={!this.props.saved}>
                    <mobiscroll.FormGroupTitle>
                      Informationen
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>

                      <div className='mbsc-grid'>
                        <div className='mbsc-row'>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'name', e.target.value) }} value={this.props.name}>Name</mobiscroll.Input>
                          </div>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <label> Ausspielung für Club
                              <mobiscroll.Select
                                value={this.props.enabledClubs}
                                onSet={(e, inst) => {
                                  this.props.handleUpdateSponsor(this.props.id, 'enabledClubs', inst.getVal())
                                }}
                              >
                                {this.props.user.license.map((license) => <option key={license.data.id} value={license.data.id}>{license.clubName}</option>)}
                              </mobiscroll.Select>
                            </label>
                          </div>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'comment', e.target.value) }} value={this.props.comment}>Interne Notizen</mobiscroll.Input>
                          </div>

                        </div>
                      </div>
                      <mobiscroll.Switch checked={this.props.inSummary} onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'inSummary', e.target.checked) }}>
                        Premium
                      </mobiscroll.Switch>

                      <div className='mbsc-grid'>
                        <div className='mbsc-row'>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6'>
                            <mobiscroll.Calendar
                              dateFormat='dd.mm.yy'
                              onClose={this.handleDatetime}
                            >
                              <mobiscroll.Input defaultValue={format.asString('dd.MM.yyyy', this.props.validFrom ? new Date(this.props.validFrom) : new Date())}>Startzeitpunkt</mobiscroll.Input>
                            </mobiscroll.Calendar>
                          </div>
                          {this.props.validTill !== '*' &&
                            <div div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6'>
                              {this.props.validTill &&
                                <mobiscroll.Calendar
                                  dateFormat='dd.mm.yy'
                                  onClose={this.handleDatetime}
                                >
                                  <mobiscroll.Input editable='false' defaultValue={format.asString('dd.MM.yyyy', new Date(this.props.validTill))}>Endzeitpunkt</mobiscroll.Input>
                                </mobiscroll.Calendar>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>
                  <mobiscroll.FormGroup collapsible open={!this.props.saved}>
                    <mobiscroll.FormGroupTitle>
                      Anzeige
                    </mobiscroll.FormGroupTitle>
                    <mobiscroll.FormGroupContent>

                      <div className='mbsc-grid'>
                        <div className='mbsc-row'>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamTextA', e.target.value) }} value={this.props.instreamTextA}>1.Zeile</mobiscroll.Input>
                          </div>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamTextB', e.target.value) }} value={this.props.instreamTextB}>2.Zeile</mobiscroll.Input>
                          </div>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamButtonText', e.target.value) }} value={this.props.instreamButtonText}>Button</mobiscroll.Input>
                          </div>
                        </div>
                      </div>
                      <mobiscroll.Input onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'link', e.target.value) }} value={this.props.link}>Link</mobiscroll.Input>
                      <h3>Farben</h3>
                      <div className='mbsc-grid'>
                        <div className='mbsc-row'>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input style={{ 'backgroundColor': this.props.instreamButtonColor }} onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamButtonColor', e.target.value) }} value={this.props.instreamButtonColor}>Button</mobiscroll.Input>
                          </div>

                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input style={{ 'backgroundColor': this.props.instreamButtonTextColor }} onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamButtonTextColor', e.target.value) }} value={this.props.instreamButtonTextColor}>Button Text</mobiscroll.Input>
                          </div>

                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-4'>
                            <mobiscroll.Input style={{ 'backgroundColor': this.props.instreamTextColor }} onChange={(e) => { this.props.handleUpdateSponsor(this.props.id, 'instreamTextColor', e.target.value) }} value={this.props.instreamTextColor}>Text</mobiscroll.Input>
                          </div>
                        </div>
                      </div>
                      <h3>Bilder</h3>
                      <div className='mbsc-grid'>
                        <div className='mbsc-row'>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6'>
                            <h4>Logo</h4>
                            <ImageUpload id={this.props.id} methode={'auto'} expectedWidth={800} expectedHeight={400} imageSelectCallback={this.props.handleImageUploadPending} imagePreviewUrl={this.props.mediaLottyInstream} />
                          </div>
                          <div className='mbsc-col-12 mbsc-col-md-6 mbsc-col-lg-6'>
                            <h4>Seitenbild</h4>
                            <ImageUpload id={this.props.id} methode={'auto'} expectedWidth={2400} expectedHeight={400} imageSelectCallback={this.props.handleImageBackgroundUploadPending} imagePreviewUrl={this.props.mediaLottyInstreamSide} />
                          </div>
                        </div>
                      </div>
                    </mobiscroll.FormGroupContent>
                  </mobiscroll.FormGroup>

                  {this.state.open && this.props.mediaLottyInstream &&
                    <div>
                      <h3>Vorschau</h3>
                      <Link to={'/preview/' + this.props.accessKey} target='_blank'>
                        <h5>Teilen</h5>
                      </Link>
                      <LottiePreview
                        lottiData={this.lottiData}
                        image={this.props.mediaLottyInstream}
                        buttonTextColor={this.props.instreamButtonTextColor}
                        textColor={this.props.instreamTextColor}
                        inStreamBackground={this.props.mediaLottyInstreamSide}
                        buttonColor={this.props.instreamButtonColor}
                        firstLine={this.props.instreamTextA}
                        secondLine={this.props.instreamTextB}
                        isPaused={!this.state.open}
                        buttonText={this.props.instreamButtonText}
                      />
                    </div>
                  }
                </mobiscroll.FormGroup>
              </div>
            </mobiscroll.CardContent>
            <div className='mbsc-btn-group'>
              <div className='mbsc-grid'>
                <div className='mbsc-row mbsc-justify-content-between'>

                  {this.props.saved &&
                    <mobiscroll.Button outline
                      color={this.props.activeLotty ? 'success' : 'warning'}
                      title={!this.props.activeLotty && this.props.activeCount >= 6 ? 'Es können nicht mehr als 6 Werbemittel gleichzeitig Aktiviert werden' : ''}
                      disabled={(!this.props.activeLotty && this.props.activeCount >= 6) || this.props.AnimatedSponsors.state.working}
                      onClick={() => { this.props.handleSetActiveSponsor(this.props.id, !this.props.activeLotty) }}>
                      {this.props.activeLotty ? 'Deaktivieren' : 'Aktivieren'}
                    </mobiscroll.Button>
                  }
                  {!this.props.saved &&
                    <mobiscroll.Button
                      outline
                      disabled={this.props.AnimatedSponsors.state.working}
                      color='primary'
                      onClick={() => { this.props.handleSaveSponsorCatched(this.props) }}
                    >
                      Speichern
                    </mobiscroll.Button>
                  }

                  {!this.props.saved &&
                    <mobiscroll.Button
                      color='danger'
                      disabled={this.props.AnimatedSponsors.state.working}
                      onClick={() => { this.props.resetEntry(this.props.id) }}
                    >
                      Verwerfen
                    </mobiscroll.Button>
                  }
                  {this.props.saved &&
                    <mobiscroll.Button
                      color='danger'
                      disabled={this.props.AnimatedSponsors.state.working}
                      onClick={() => { this.props.handleDeleteSponsor(this.props) }}
                    >
                      Löschen
                    </mobiscroll.Button>
                  }
                </div>
              </div>
            </div>

          </mobiscroll.Card>
        </div>
      </div >
    )
  }
}
