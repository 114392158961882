import React, { Component } from 'react'
import Jimp from 'jimp'
import mobiscroll from '@mobiscroll/react'

export default class ImageUpload extends Component {
  constructor (props) {
    super(props)
    this._handleImageChange = this._handleImageChange.bind(this)
    this.applyFilter = this.applyFilter.bind(this)

    this.state = { rawImage: undefined }
  }

  _handleImageChange (e) {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = async () => {
      let imageData = reader.result.substring(reader.result.indexOf('base64,') + 'base64,'.length)

      try {
        let image = await Jimp.read(Buffer.from(imageData, 'base64'))
        await this.setState({ rawImage: image })

        this.applyFilter(image, this.props.methode)
      } catch (err) {
        mobiscroll.toast({
          message: 'Upload nicht möglich: ' + err,
          color: 'warning'
        })
      }
    }

    reader.readAsDataURL(file)
  }

  async applyFilter (image, methode) {
    let type = `png`
    image.background(image.getPixelColor(0, 0))
    let ratio = image.bitmap.width / image.bitmap.height
    let desiredRatio = this.props.expectedWidth / this.props.expectedHeight

    if (methode === 'auto') {
      methode = (desiredRatio / ratio > 1.0) ? 'cover' : 'contain'
    }
    if (methode === 'cover') {
      image = await image.cover(this.props.expectedWidth || 800, this.props.expectedHeight || 400)
    } else {
      image = await image.contain(this.props.expectedWidth || 800, this.props.expectedHeight || 400, Jimp.HORIZONTAL_ALIGN_CENTER | Jimp.VERTICAL_ALIGN_MIDDLE)
    }
    let rawData = await image.getBase64Async(Jimp.MIME_PNG)

    this.props.imageSelectCallback(this.props.id, rawData, type)
  }

  render () {
    let $imagePreview = null
    if (this.props.imagePreviewUrl) {
      $imagePreview = (<img src={this.props.imagePreviewUrl} />)
    } else {
      $imagePreview = (<h3>Noch kein Banner vorhanden</h3>)
    }

    return (
      <div className='previewComponent'>
        <div className='mbsc-grid'>
          <div className='mbsc-row'>
            <div className='mbsc-col-12 mbsc-col-sm-6 mbsc-col-lg-6 mbsc-col-xl-4'>
              <div className='imgPreview'>
                {$imagePreview}

              </div>
            </div>
            <div className='mbsc-col-12 mbsc-col-sm-6 mbsc-col-lg-6 mbsc-col-xl-8'>
              <form onSubmit={(e) => this._handleSubmit(e)}>
                <mobiscroll.Input
                  type='file'
                  placeholder='Bild zum Upload'
                  onChange={(e) => this._handleImageChange(e)}>
                    Upload</mobiscroll.Input>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
